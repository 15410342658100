<template>
    <fragment>
        <vue-particles
                color="#fff"
                :particleOpacity="0.7"
                :particlesNumber="60"
                shapeType="circle"
                :particleSize="4"
                linesColor="#fff"
                :linesWidth="1"
                :lineLinked="true"
                :lineOpacity="0.4"
                :linesDistance="150"
                :moveSpeed="2"
                :hoverEffect="true"
                hoverMode="grab"
                :clickEffect="true"
                clickMode="push"
                class="login_background"
        ></vue-particles>
        <div class="login-content">
            <div class="logo">
                <img :src="system_logo" v-if="system_logo" alt="">
                <img  src="../assets/logo.png" v-else alt="">
            </div>
            <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
                <a-form-item label="登陆账号">
                    <a-input size="large" :disabled="readonly"  v-model="loginData.account" placeholder="请输入登陆账号"
                    ></a-input>
                </a-form-item>
                <a-form-item label="登陆密码" style="margin-bottom:25px;">
                    <a-input-password size="large" v-model="loginData.password" placeholder="请输入登陆密码"
                    ></a-input-password>
                </a-form-item>

                <a-form-item label="记住密码" style="margin-bottom:5px;">
                    <a-checkbox size="large" v-model="holdLogin"></a-checkbox>
                    <el-button :style="{marginLeft:'80px'}" type="primary" @click="login">提交</el-button>
                    <el-button :style="{marginLeft:'20px'}" @click="reset">重置</el-button>
                </a-form-item>
            </a-form>
        </div>
    </fragment>
</template>

<script>

    export default {
        name: "Login",
        data() {
            return {
                system_logo:'../assets/logo.png',                  //项目LOGO
                system_name:'Lucky3.2',                  //项目名称
                loginData: {account:'',password:''},  //登陆数据
                holdLogin:false,                      //是否保持登陆
                readonly:true
            }
        },

        methods:{
            reset(){
                this.loginData = {account:'',password:''};
            },
            login(){
                let that = this;
                that.$ajax.post('/auth/Admin/login',that.loginData)
                    .then(res=>{
                        localStorage.setItem('Authorization', res.token);
                        that.$message.success({message:"登陆成功",showClose: true});
                        if(that.holdLogin){
                            localStorage.setItem('holdLogin.account', that.loginData.account);
                            localStorage.setItem('holdLogin.password', that.loginData.password);
                            localStorage.setItem('holdLogin.status', 'true');
                        }else{
                            localStorage.removeItem('holdLogin.account');
                            localStorage.removeItem('holdLogin.password');
                            localStorage.setItem('holdLogin.status', 'false');
                        }
                        setTimeout(function()  {
                            that.$router.push('/home')
                        }, 1000);
                    })
            }
        },

        mounted() {
            let that = this;
            //获取系统信息
            this.$ajax.post('/auth/admin/beforeLogin', {}).then(res=>{
                document.title = res.system_name?res.system_name:'Lucky3.2';
                that.system_name = res.system_name?res.system_name:'Lucky3.2';
                that.system_logo = res.system_logo;
                //已经有token直接跳转到后台首页
                if(localStorage.getItem('Authorization')){
                    that.$router.push('/home')
                }else {
                    if(localStorage.getItem('holdLogin.status') == 'true'){
                        that.readonly = false
                        that.loginData.account = localStorage.getItem('holdLogin.account');
                        that.loginData.password = localStorage.getItem('holdLogin.password');
                        that.holdLogin = true
                    }else {
                        setTimeout(function () {
                            that.readonly = false
                            that.loginData.account = "";
                            that.loginData.password = '';
                            that.holdLogin = false
                        },1000)
                    }
                }
            })

        },
    }
</script>

<style scoped lang="less">
    .login_background{
        width: 100%;
        height: 100%;
        background-image: -webkit-linear-gradient(top, #02164f, #000);
        background-image: -moz-linear-gradient(top, #02164f, #000);
        background-image: -ms-linear-gradient(top, #02164f, #000);
        background-image: -o-linear-gradient(top, #02164f, #000);
        background-image: linear-gradient(to bottom, #02164f, #000);
        background-color: #1A1A1A;
    }


    .login-content{
        z-index: 999;
        width: 480px;
        height: 290px;
        background-color: white;
        border-radius: 10px;
        position: absolute;
        top: 30vh;
        left: calc(50vw - 240px)
    }
    .logo{
        width: 100px;
        height: 100px;
        background-color: white;
        position: absolute;
        top: -50px;
        left: calc(240px - 50px);
        border-radius: 54px;
        padding: 4px;
        img{
            width: 100%;
            height: 100%;
            border-radius: 50px;
        }
    }
    .ant-form{
        width: 70%;
        position: absolute;
        top: 70px;
        left: 60px;
    }

</style>